<template>
  <section class="bg-light p-3">
    <h2 class="text-primary">Nouvel article</h2>
    <hr />
    <form class="row g-3" @submit.prevent="confirm">
      <div class="col-12">
        <label for="title-article" class="form-label">Titre de l'article</label>
        <input
          type="text"
          name="title-article"
          id="title-article"
          autocomplete="off"
          class="form-control"
          required
          v-model="form.title_article"
        />
      </div>
      <div class="col-md-6">
        <label for="categorie-article" class="form-label">Catégorie</label>
        <select
          name="categorie-article"
          id="categorie-article"
          class="form-control"
          required
          v-model="form.id_categorie_article"
        >
          <option value="categorie-0" style="display: none"></option>
          <option
            :value="categorie.id_categorie_article"
            v-for="categorie in categorieArticle"
            :key="categorie.id_categorie_article"
          >
            {{ categorie.name_categorie_article }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="img-article" class="form-label">Image d'illustration</label>
        <input
          type="file"
          accept="image/jpeg, image/png"
          name="img-article"
          class="form-control"
          id="img-article"
          required
          @change="changeFile"
        />
      </div>
      <div class="col-12">
        <label for="description-article" class="form-label">Description</label>
        <textarea
          name="description-article"
          id="description-article"
          class="form-control"
          rows="3"
          style="resize: none"
          required
          v-model="form.description_article"
        />
      </div>
      <div class="col-12">
        <label class="form-label">Contenu principal</label>
        <div class="vueEditor">
          <vue-editor
            :customModules="customModulesForEditor"
            :editor-options="editorSettings"
            required
            v-model="form.content_article"
          ></vue-editor>
        </div>
      </div>
      <div class="col-12 mb-2 mt-4 text-end">
        <div
          v-if="loading"
          class="ms-5 spinner-border text-warning"
          role="status"
        ></div>
        <button v-else type="submit" class="btn btn-primary">Ajouter</button>
      </div>
    </form>
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor";
import BlotFormatter from "quill-blot-formatter";
import { addArticle } from "../../api/article";
import { success, error } from "../../utils/toast";
import { getAllCategoryArticle } from "../../api/categorieArticle";

export default {
  name: "ArticleNouveau",
  components: {
    VueEditor,
  },
  data() {
    return {
      customModulesForEditor: [
        { alias: "BlotFormatter", module: BlotFormatter },
      ],
      editorSettings: {
        modules: {
          BlotFormatter: true,
        },
      },
      form: {
        image: null,
        categorie_article: "",
        title_article: "",
        description_article: "",
        content_article: "",
      },
      loading: false,
      categorieArticle: [],
    };
  },
  mounted() {
    getAllCategoryArticle().then((result) => {
      this.categorieArticle = result.data;
    });
  },
  methods: {
    changeFile(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const base64 = e.target.result;
        this.form.image = base64;
      };
    },
    confirm() {
      this.loading = true;
      addArticle(this.form).then((result) => {
        if (result.data.error) {
          error(result.data.error);
        } else {
          this.$store.dispatch("articleStore/addArticle", result.data);
          success("Article ajouté avec succès");
          this.form.title_article = "";
          this.form.image = "";
          this.form.description_article = "";
          this.form.content_article = "";
          this.form.categorie_article = "";
          document.getElementById("img-article").value = "";
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  min-height: 100vh;
}

.vueEditor {
  background-color: white;
}
</style>
